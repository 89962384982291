export enum PermissionsEnum {
  ADMIN_TAB = 'ADMIN_TAB',
  CALENDAR_TAB = 'CALENDAR_TAB',
  CAN_RESOLVE_USER_DAY_STATUSES = 'CAN_RESOLVE_USER_DAY_STATUSES',
  CONTACTS_TAB = 'CONTACTS_TAB',
  CREATE_USERS = 'CREATE_USERS',
  DISMISS_USERS = 'DISMISS_USERS',
  DOWNLOAD_USERS_EXCEL = 'DOWNLOAD_USERS_EXCEL',
  GENERATE_BONUS_REPORT = 'GENERATE_BONUS_REPORT',
  GENERATE_DOCUMENTS = 'GENERATE_DOCUMENTS',
  GENERATE_FINANCE_REPORT = 'GENERATE_FINANCE_REPORT',
  GENERATE_GENERAL_REPORT = 'GENERATE_GENERAL_REPORT',
  GENERATE_NOT_LOCKED_DAYS_REPORT = 'GENERATE_NOT_LOCKED_DAYS_REPORT',
  GENERATE_NOT_TRACKED_DAYS_REPORT = 'GENERATE_NOT_TRACKED_DAYS_REPORT',
  GENERATE_PROJECT_TIME_REPORT = 'GENERATE_PROJECT_TIME_REPORT',
  GENERATE_TEAM_REPORT = 'GENERATE_TEAM_REPORT',
  GENERATE_TRACKED_PROJECT_REPORT = 'GENERATE_TRACKED_PROJECT_REPORT',
  GENERATE_USER_CV = 'GENERATE_USER_CV',
  HIERARCHY_TAB = 'HIERARCHY_TAB',
  MANAGE_AUTO_LOCK_PERIOD = 'MANAGE_AUTO_LOCK_PERIOD',
  MANAGE_BONUSES = 'MANAGE_BONUSES',
  MANAGE_CAROUSEL = 'MANAGE_CAROUSEL',
  MANAGE_CERTIFICATES = 'MANAGE_CERTIFICATES',
  MANAGE_COMPANY_DAY_STATUSES = 'MANAGE_COMPANY_DAY_STATUSES',
  MANAGE_COMPANY_EVENTS = 'MANAGE_COMPANY_EVENTS',
  MANAGE_DEPARTMENTS = 'MANAGE_DEPARTMENTS',
  MANAGE_EDUCATION_EXPERIENCE = 'MANAGE_EDUCATION_EXPERIENCE',
  MANAGE_FINANCE_INFORMATION = 'MANAGE_FINANCE_INFORMATION',
  MANAGE_GENDERS = 'MANAGE_GENDERS',
  MANAGE_HIERARCHY = 'MANAGE_HIERARCHY',
  MANAGE_JIRA = 'MANAGE_JIRA',
  MANAGE_JIRA_EMAIL = 'MANAGE_JIRA_EMAIL',
  MANAGE_NEWS = 'MANAGE_NEWS',
  MANAGE_NEWS_FEED = 'MANAGE_NEWS_FEED',
  VIEW_TERMINATION_ISSUE = 'VIEW_TERMINATION_ISSUE',
  MANAGE_NOTE_TEMPLATES = 'MANAGE_NOTE_TEMPLATES',
  MANAGE_NOTIFICATIONS = 'MANAGE_NOTIFICATIONS',
  MANAGE_OAUTH_CLIENT = 'MANAGE_OAUTH_CLIENT',
  MANAGE_OFFICES = 'MANAGE_OFFICES',
  MANAGE_RELEASE_NOTES = 'MANAGE_RELEASE_NOTES',
  MANAGE_POSITIONS = 'MANAGE_POSITIONS',
  MANAGE_PROJECTS = 'MANAGE_PROJECTS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_SKILLS = 'MANAGE_SKILLS',
  MANAGE_SPECIAL_USER_SETTINGS = 'MANAGE_SPECIAL_USER_SETTINGS',
  MANAGE_SPECIALIZATIONS = 'MANAGE_SPECIALIZATIONS',
  MANAGE_TASKS = 'MANAGE_TASKS',
  MANAGE_TEAMS = 'MANAGE_TEAMS',
  MANAGE_USER_DAY_INFO_STATUS_TYPES = 'MANAGE_USER_DAY_INFO_STATUS_TYPES',
  MANAGE_USER_DAY_STATUSES = 'MANAGE_USER_DAY_STATUSES',
  MANAGE_USER_EXPERIENCE = 'MANAGE_USER_EXPERIENCE',
  MANAGE_USER_LANGUAGES = 'MANAGE_USER_LANGUAGES',
  MANAGE_USER_SETTINGS = 'MANAGE_USER_SETTINGS',
  MANAGE_USER_SKILLS = 'MANAGE_USER_SKILLS',
  MANAGE_USER_TEAM = 'MANAGE_USER_TEAM',
  MANAGE_USERS = 'MANAGE_USERS',
  PORTFOLIO_TAB = 'PORTFOLIO_TAB',
  REFRESH_EVENTS = 'REFRESH_EVENTS',
  REPORTS_TAB = 'REPORTS_TAB',
  RESOLVE_OVERTIME_REQUESTS = 'RESOLVE_OVERTIME_REQUESTS',
  RESOLVE_USERS_DAY_OFF = 'RESOLVE_USERS_DAY_OFF',
  RESTORE_USERS = 'RESTORE_USERS',
  SPD_REQUESTS_TAB = 'SPD_REQUESTS_TAB',
  SPD_WIKI_TAB = 'SPD_WIKI_TAB',
  SUBSCRIBE_TO_USERS = 'SUBSCRIBE_TO_USERS',
  UNLOCK_PERIOD = 'UNLOCK_PERIOD',
  UPDATE_VACATION_BALANCE = 'UPDATE_VACATION_BALANCE',
  USERS_TAB = 'USERS_TAB',
  VIEW_ALL_PROJECTS = 'VIEW_ALL_PROJECTS',
  VIEW_ALL_TEAMS = 'VIEW_ALL_TEAMS',
  VIEW_ALL_USER_DAY_STATUSES = 'VIEW_ALL_USER_DAY_STATUSES',
  VIEW_ALL_USERS = 'VIEW_ALL_USERS',
  VIEW_CERTIFICATES = 'VIEW_CERTIFICATES',
  VIEW_CUSTOMER_DIRECT_CONTRACT_USERS = 'VIEW_CUSTOMER_DIRECT_CONTRACT_USERS',
  VIEW_EVENTS = 'VIEW_EVENTS',
  VIEW_FINANCIAL_INFORMATION = 'VIEW_FINANCIAL_INFORMATION',
  VIEW_HIERARCHY = 'VIEW_HIERARCHY',
  VIEW_NEWS_FEED = 'VIEW_NEWS_FEED',
  VIEW_TASK_EVENTS = 'VIEW_TASK_EVENTS',
  VIEW_TASKS = 'VIEW_TASKS',
  VIEW_UNLOCK_PERIOD_REQUEST = 'VIEW_UNLOCK_PERIOD_REQUEST',
  VIEW_USER_CHILDREN = 'VIEW_USER_CHILDREN',
  VIEW_USER_TEAM = 'VIEW_USER_TEAM',
  VIEW_VACATION_BALANCE = 'VIEW_VACATION_BALANCE',
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from '@config/environment';

import { AuthGuard } from './_shared/services/auth/auth.guard';
import { LogoutGuard } from './_shared/services/auth/logout.guard';
import { LoginUrlRedirectResolver } from './_shared/services/login-url-redirect/login-url-redirect.resolver';
import { MediaUrlRedirectResolver } from './_shared/services/media-url-redirect/media-url-redirect.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'launchpad', pathMatch: 'full' },
  {
    path: 'launchpad',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./launchpad/launchpad.module').then(m => m.LaunchpadModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./top-component/top.module').then(m => m.TopModule),
    resolve: {
      url: LoginUrlRedirectResolver,
    },
    data: {
      loginUrl: environment.authRedirectUrl,
    },
  },
  {
    path: 'media/:code',
    loadChildren: () => import('./top-component/top.module').then(m => m.TopModule),
    resolve: {
      url: MediaUrlRedirectResolver,
    },
    data: {
      mediaUrl: environment.authRedirectUrl,
    },
  },
  {
    path: 'logout',
    canActivate: [ LogoutGuard ],
    loadChildren: () => import('./logout-page/logout-page.module').then(m => m.LogoutPageModule),
  },
  {
    path: 'settings',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./admin-panel/admin-panel.module').then(m => m.AdminPanelModule),
  },
  {
    path: 'news-constructor',
    canActivate: [ AuthGuard ],
    loadChildren: () =>
      import('./admin-panel/manage-news-feed/news-constructor/news-constructor.module').then(m => m.NewsConstructorModule),
  },
  {
    path: 'news-feed',
    canActivate: [ AuthGuard ],
    loadChildren: () =>
      import('./news-feed/news-feed.module').then(m => m.NewsFeedModule),
  },
  {
    path: '**',
    redirectTo: 'launchpad',
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
